
.tablePricing {
    width: 100%;
    background-color: white;
    border-spacing: 0;
    font-size: 0.8rem;
}

.tablePricing td {
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
}

.subHeader {
    padding: 18px 0 9px 0;
    font-size: 1rem;
}

.tableItem {
    border-left: 1px solid lightgray;
    font-size: 1rem;
}

.normalPrice {
    text-decoration: line-through;
    color: #a6a6a6;
}

.stickyHeader {
    position: sticky;
    top: 0;
    background: #ffb703;
    color: #1B1A17;
    font-weight: bold;
    padding: 16px 0 16px 0;
}

.extensionHeader {
    top: 0;
    background: #ff8a00;
    font-weight: bold;
    padding: 16px 0 16px 0;
    border: 1px solid #ff8a00;
}

.arrowContainer {
    position: absolute;
    text-align: center;
    bottom: -21px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.arrow {
    border: solid #ffb703;
    border-width: 0 6px 6px 0;
    display: inline-block;
    padding: 6px;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
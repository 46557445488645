.leaflet-container {
    height: 100%;
}
.leaflet-div-icon {
    background: transparent;
    border: none;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    border: none;
}
.leaflet-left .leaflet-control {
    padding: 0;
    margin-left: 0;
    width: 100%;
}
.leaflet-bottom {
    width: 100%;
}
.leaflet-bottom div{
    padding: 10px;
}

.leaflet-control-attribution {
    display: none;
}